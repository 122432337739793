<template>
  <div>
    <v-menu
      v-model="datePicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          dense
          filled
          flat
          :label="prop_label"
          hide-details
          solo
          clearable
          v-on:click:clear="dates = []"
          prepend-inner-icon="mdi-calendar"
          readonly
          :loading="pageLoading"
          :disabled="pageLoading"
          v-bind="attrs"
          v-on="on"
          :value="formattedDate"
          color="cyan"
        ></v-text-field>
      </template>
      <v-date-picker
        no-title
        color="cyan"
        range
        v-model="dates"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  props: {
    prop_dates: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    prop_label: {
      type: String,
      default: "Date Range",
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    prop_dates: {
      deep: true,
      immediate: true,
      handler() {
        const dates = this.lodash.cloneDeep(this.dates);
        const props = this.lodash.cloneDeep(this.prop_dates);
        if (props && this.lodash.isArray(props) && props.length === 2) {
          if (this.lodash.isEqual(dates, props) === false) {
            this.dates = props;
          }
        }
      },
    },
    dates: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("input", this.dates);
        this.$emit("change", this.dates);
        if (this.dates.length > 1) {
          this.datePicker = false;
        }
      },
    },
  },
  data() {
    return {
      dates: [],
    };
  },
  name: "date-range-picker",
};
</script>
